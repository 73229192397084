///////*From 2016 Renew*///////
$(function () {
	//IE
	var ua = navigator.userAgent.toLowerCase();
	var ver = navigator.appVersion.toLowerCase();
	// IE(11以外)
	var isMSIE = (ua.indexOf('msie') > -1) && (ua.indexOf('opera') == -1);
	// IE11
	var isIE11 = (ua.indexOf('trident/7') > -1);
	// Edge
	var isEdge = (ua.indexOf('edge') > -1);
	// IE
	var isIEnotEdge = isMSIE || isIE11;
	var isAllIE = isMSIE || isIE11 || isEdge;
	if(isIEnotEdge) {
    $("body").addClass('ieStyle')
	} else if(isEdge) {
    $("body").addClass('edgeStyle')
	}

	//２階層目のディレクトリ名を取得
	var $dir = location.href.split("/");
	var $dir2 = $dir[3];

	///*スムーズスクロール*///
	$('a[href^="#"]' + 'a:not(.no_scroll)').click(function(){
		var speed = 600;
		var href= $(this).attr("href");
		var target = $(href === "#" || href === "" ? 'html' : href);
		var w = window.outerWidth;
		if(w > 640 && $dir2 !== 'opinions' && $dir2 !== 'program' && $dir2 !== 'case_introduction' && $dir2 !== 'global-sa'){
			var headerHeight = 100;
		} else if ($dir2 == 'opinions' || $dir2 == 'program' || $dir2 == 'case_introduction' || $dir2 == 'global-sa'){
			var headerHeight = 0;
		}else{
			var headerHeight = $('#header').height();
		}
		var position = target.offset().top - headerHeight;
		$("html, body").animate({scrollTop:position }, speed, "swing");
		return false;
	});

	//splash
		$('.onSplash').mouseover(
			function(){ $(this).stop().css({'opacity':0.7}).animate({'opacity':1},400);
		});

	//onAniOver
		$('.onAniOver').hover(
			function(){$(this).stop().animate({'opacity':0.7},400);},
			function(){$(this).stop().animate({'opacity':1});}
		);

	//onAniOver
		$('.onAniTransparent').hover(
			function(){$(this).stop().animate({'opacity':0},400);},
			function(){$(this).stop().animate({'opacity':1});}
		);

	//onOver
		$('.onOver').hover(
			function() {$(this).css({'opacity':0.7});},
			function() {$(this).css({'opacity':1});}
		);

	//image-replace-over
		$('.onImgOver').each(function() {
			var _image = this.src;
			var _extension = _image.substr(_image.lastIndexOf("."), _image.length-1);
			var _imageOver = _image.replace(_extension, "-over"+_extension);
			new Image().src = _imageOver;
			$(this).hover(
				function(){this.src = _imageOver;},
				function(){this.src = _image;}
			);
		});

	//image-retina
		var agent = navigator.userAgent;
		if(agent.search(/iPad/) !== -1){
			$(".x2").each(function() {
				var image = this.src;
				var _extension = image.substr(image.lastIndexOf("."), image.length-1);
				this.src = image.replace(_extension, "@2x"+_extension);
			});
		}

	//COOKIEの承認
	function CookieCheck(){
	  //ブラウザにあるクッキーの情報を取得
	  var cokkieText = document.cookie;
	  //取得したクッキーに文字列「visited」があるか確認（「indexOf」は指定した文字列が何文字目にあるか検索するもの。なければ「-1」が返る）
	  var BooleanCheck = cokkieText.indexOf("visited",0);
	  //クッキーのバナー
	  var cookie_bnr = $('.cookie_approval');
	  //クッキーの有り無しで処理を分岐
	  if(BooleanCheck == -1){//クッキー無しの場合
			//承認を求めるバナーが出て、ボタンを押すと閉じる
			cookie_bnr.addClass('show');
			$('#header,body').addClass('have_cookie');
			$('#navWrap').animate({
				'marginTop': '500px'
			});
			$('.js_btn_close_approval').on('click', function(){
				cookie_bnr.addClass('close')
				$('#header').removeClass('have_cookie');
				$('body').addClass('close_cookie');
	      //ブラウザにクッキーを渡す
	      document.cookie = 'data=visited; path=/; expires=Tue, 19 Jan 2038 03:14:07 GMT';//1年(365日)	31536000秒
			});
	  }else{//クッキー有りの場合
		  $('.cookie_approval').remove();
		}
	}
	CookieCheck();

	///header切り替え*///
		var header = $('#header,.cookie_approval');
		var headerHeight = header.height();
    $(window).scroll(function () {
      if ($(this).scrollTop() > headerHeight) {
        header.addClass('fixed');
      } else {
        header.removeClass('fixed');
      }
    });

	///*header色current変更*///
    var urlPath = location.pathname.split("/")[1];
		$(document).ready(function() {
		  if(urlPath === "") {
				$("header nav li a[href='/']").addClass('current');
		  } 
		  if(urlPath === "online"){
		  	$("header nav li a[href*='"+urlPath+"']").addClass('current');
		  	$("header nav li a[href*='/reskilling/onlineliveacademy/']").removeClass('current');
		  }else{
		  	$("header nav li a[href*='"+urlPath+"']").addClass('current');
		  }
		});

	///*aside(サブメニュー)に.currentのクラス付加*///
    var url = location.pathname;
		var splitUrl = url.split("/");	//URLをsplitで区切る
		var fileNameZ = url.split("/").pop();	//URL最後の値（ファイル名）を取得
		var fileNameY = splitUrl[splitUrl.length - 2];	//URLの最後から2番目の値(ディレクトリ名)を取得
		var filename_ex = fileNameY+"/"+fileNameZ;	//URLの最後から2番目+ファイル名

	  $(document).ready(function() {
		  if(fileNameZ === "" || fileNameZ === "index.html" || filename_ex === "" ){
				$("#aside #subNav a[href$='/'],#aside #subNav a[href$='"+url+"'],#aside #subNav a[href$='/index.html']").addClass('current');
				$("#newAside .navBox .sideNavList > li a[href$='/'],#newAside .navBox .sideNavList > li a[href$='" + url + "'],#newAside .navBox .sideNavList > li a[href$='index.html']").parent().addClass('current');
	    }else{
	      $("#aside #subNav a[href*='"+ filename_ex +"']").addClass('current');
	      $("#consulting #aside #subNav a[href*='"+ fileNameZ +"']").addClass('current');
				$("#newAside .navBox .sideNavList > li a[href*='" + filename_ex + "']").parent().addClass('current');
			}
		});

	///*お問い合わせエリアの文言変更*///
		$(function(){
			var titleMainName = $("#pageTitle .titleMain").text();
			$('#contactLabelName,#contactNotesName').each(function(){
				var txt = $(this).text();
				$(this).text(
					txt.replace(/このコンテンツ/g,titleMainName)
				);
			});
		});

	///*scheduleListの表の背景色の処理*///
		$('.scheduleList > li:even').css({'background':'#eff6fc'});

	///*tab_menu1
		$(function(){
	    $('.tabbox:first').show();
	    $('.tabmenu li:first').addClass('selected');
	    $('.tabmenu li').click(function() {
        $('.tabmenu li').removeClass('selected');
        $(this).addClass('selected');
        $('.tabbox').hide();
        $($(this).find('a').attr('href')).fadeIn();
        return false;
	    });
		});

	///*映像教材ポップアップ*///
		$('.comingPopup').magnificPopup({
			type: 'inline',
			preloader: false,
			closeOnBgClick: true
		});

	///*aside(サブメニュー)出し分け処理*///
		(function() {
		  var categoryName = $("#caseGenre").text();

			$("[data-titleExplan='"+categoryName+"']").addClass("currentTheme");
			$('#aside #subNav li.currentTheme').show();
		})();

	/* シンプルなPOPUP
	-------------------------------------------------- */
	$('.popuOpen').click(function(){
		var innerName = $('.popupPlate');
		var dname = $(this).attr('data-popupName');
		var popHeight = innerName.height();
		var windowHeight = document.documentElement.clientHeight;
		$('.'+dname).addClass('open');
		if(!$('.popupBgi').length){$('.popup').append('<div class="popupBgi"></div>');}
		$('.popupBgi,.popupClose').click(function(){
			$('.popup').removeClass('open');
			innerName.removeClass('plateOver');
		})
		if(popHeight >= windowHeight) {
			innerName.addClass('plateOver');
		}
	})

	/* 派遣プログラムの国内・海外でサブナビのcurrent処理分け
	-------------------------------------------------- */
	//◯階層目のディレクトリ名を取得
	var $dir = location.href.split("/");
	var $dir2 = $dir[3];
	var $dir3 = $dir[4];
	if($dir2=='open_class') {
		if($dir3=='japan') {
			$('#aside #asideIn dd li a[href*="/open_class/japan.html"]').addClass('current')
		} else {
			$('#aside #asideIn dd li a[href*="/open_class/overseas.html"]').addClass('current')
		}
	}
	/* POPUP
	-------------------------------------------------- */
	$('.js_popup_img').click(function(){
		var cloneImg = $(this).html();
		$("body").append("<div class='js_popuplate'></div>");
		$('.js_popuplate img').remove();
		$('.js_popuplate').addClass('open').append(cloneImg);
		$('.js_popuplate.open').on('click',function(){
			$('.js_popuplate').remove();
		});
	})
	/* メールマガジン登録ポップアップ
	-------------------------------------------------- */
	// 黄色リードエリア ロジック
	$(window).on('load scroll', function(){
		var $startPosi = $('#header').outerHeight() + $(window).outerHeight()/2;
		if($startPosi < $(this).scrollTop() && !$('.mail_popup').hasClass('open') && !$('.mail_popup').hasClass('close')){
			$('.mail_popup').addClass('open');
		}
	});

	$('.mail_popup .close_btn').on('click', function(){
		$('.mail_popup').removeClass('open');
		$('.mail_popup').addClass('close');
	});


	/* サイドメニューの位置
	-------------------------------------------------- */

	function calcHeight() {
		//#titleAreaの高さ取得
		var titleHeight = $("#titleArea").outerHeight();
		
		//asideの.topLinkの高さを取得
		var topLinkHeight = $('.topLink').outerHeight();
		
		//計算します
		var cssTop = titleHeight - topLinkHeight;
		
		return cssTop;
	}
	$(window).on('load resize', function(){
		var w = window.outerWidth;
		if (w > 640) {
			var bodyAsideClass = $('body').attr('class');
			if ($('#newAside').length && bodyAsideClass && bodyAsideClass.indexOf('-jsAsideException') == -1 ) {
				//出てきた値を「#newAside」のtopに設定する
				$('#newAside').css('top', calcHeight()).addClass('on');
			} else {
				$('#newAside').addClass('on');
			}
			if ($('#aside').length && bodyAsideClass && bodyAsideClass.indexOf('openClassAsideNew') == -1 ) {
				var cssTop = calcHeight() - 1;
				$('#asideIn').css('margin-top', cssTop).addClass('on');
			} else {
				$('#asideIn').addClass('on');
			}
		} else { 
			$('#newAside').addClass('on');
			$('#asideIn').addClass('on');
		}
	});

});